@import "../../../styles/variables.scss";

.dg-step-tracker {
    padding-left: 0px;
    li.dg-step {
        list-style-type: none;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .dot {
            width: 10px;
            height: 10px;
            margin-left: 0.5px;
            border-radius: 11px;
            border: 2px solid rgba(255,255,255,0.3);
            margin-right: 10px;
        }

        h3 {
            font-size: 15px;
            margin-bottom: 0px;
            color: rgba(255,255,255,0.5)
        }

        &.active {
            .dot {
                width: 12px;
                height: 12px;
                margin-left: 0;
                margin-right: 10px;
                background-color: $brand;
                border: none;
            }

            h3 {
                font-weight: bold;
                font-size: 16px;
                color: $brand;
            }
        }

        &.complete {
            .dot {
                margin-left: 0;
                margin-right: 11px;
                border: none;
                border-radius: none;
                color: white;
                height: 15px;
                width: 12px;
                text-align: left;
                margin-left: -1px;

                .material-icons {
                    font-size: 15px;
                }
            }

            h3 {
                color: rgba(255,255,255,0.7);
            }
        }
    }
}