@import "../../../styles/variables.scss";

.dg-giftAidDec + .dg-footer {
    display: none;
}

.dg-giftAidDec-viewer {
    width: 100%;
    height: 100vh;
}



@media print {
    .dg-poster .no-print {
        display: none;
    }
    
}