@font-face {
    font-family: 'Effra';
    src: url('Effra-Heavy.woff2') format('woff2'),
        url('Effra-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Effra';
    src: url('Effra-Light.woff2') format('woff2'),
        url('Effra-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Effra';
    src: url('Effra-Medium.woff2') format('woff2'),
        url('Effra-Medium.woff') format('woff'),
        url('Effra-Medium.ttf') format('ttf');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Effra';
    src: url('Effra-Regular.woff2') format('woff2'),
        url('Effra-Regular.ttf') format('ttf'),
        url('Effra-Regular.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Effra';
    src: url('Effra-Bold.woff2') format('woff2'),
        url('Effra-Bold.ttf') format('ttf'),
        url('Effra-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intelo Alt';
    src: url('InteloAlt-Bold.woff2') format('woff2'),
        url('InteloAlt-Bold.ttf') format('ttf'),
        url('InteloAlt-Bold.eot') format('eot'),
        url('InteloAlt-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Intelo Alt';
    src: url('InteloAlt-Medium.woff2') format('woff2'),
        url('InteloAlt-Medium.ttf') format('ttf'),
        url('InteloAlt-Medium.eot') format('eot'),
        url('InteloAlt-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

/* Colours */

$brand: #FF8F1C;
$orange: #FF8F1C;
$primary: $orange;
$text-dark: #333;
$text-light: #fff;
$heading-color: #444;

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;

/* Sizes */

$font-size-base: 0.9rem;
$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.5 !default;
$h4-font-size:                $font-size-base !default;
$font-size-lg:                $font-size-base !default;
$font-size-sm:                $font-size-base !default;

/* Border radius */

$border-radius:               0px !default;
$border-radius-lg:            0px !default;
$border-radius-sm:            0px !default;

@import "~bootstrap/scss/bootstrap";

@media print {
    body.dg-print-nomargin {
        margin: 0mm !important;
        padding: 0mm !important;
    }
}