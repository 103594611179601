@import "../../../../styles/variables.scss";
.dg-empty {
    padding: 50px;
    text-align: center;
    width: 100%;
    height: 100%;

    .material-icons {
        font-size: 4em;
        margin-bottom: 20px;
        color: $gray-400;
    }

    h4 {
        color: $gray-900;
        font-weight: 500;
    }
}