@import "../../../../styles/variables.scss";
.dg-table {
    width: calc(100% + 40px);
    margin: 0 -20px 0 -20px;
    thead {
        th {
            background-color: $gray-100;
            border-top: 1px solid $gray-200;
            color: $gray-800;
            font-size: 12px;
            text-transform: uppercase;

            &:hover {
                background-color: $gray-200;
            }

            .material-icons {
                font-size: 16px;
                position: relative;
                top: 3px;
                margin-left: 3px;
                color: $gray-500;
            }
        }

        .dg-filter {
            padding: 10px 20px;
            width: 100%;
            

            th {
                background-color: white;
            }

            input {
                width: 100%;
                padding: 0px;
                border: none;
                font-size: 16px;
            }
        }
    }

    th, td {
        border-bottom: 1px solid $gray-200;
        padding: 10px 20px 10px 10px;
        font-size: 0.9em;

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }

        .material-icons {
            color: $gray-600;
            cursor: default;
        }
    }

    tbody {
        tr {
            &:hover {
                background-color: $gray-100;
            }
        }
    }

    .dg-table-actions {
        display: flex;
        flex-direction: row;
        button {
            border: none;
            border-radius: 30px;
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0,0,0,0.1);
            
            &:not(:last-child) {
                margin-right: 10px;
            }

            .material-icons {
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
}

.dg-page-controls {
    background-color: $gray-100;
    margin-bottom: -20px !important;
}

.dg-pagination, .dg-page-controls {
    width: calc(100% + 40px);
    margin: 0 -20px 0 -20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.9em;
    padding: 10px 20px;
    border-bottom: 1px solid $gray-200;

    div {
        display: flex;
        align-items: center;
    }

    p {
        display: inline-block;
        font-size: 0.9em;
        margin: 0 10px;
        color: $gray-600;

        strong {
            color: $gray-800;
        }
    }

    button {
        border-radius: 20px;
        border: 0px;
        width: 25px;
        height: 25px;
        padding: 2.5px 0;
        background-color: $gray-200;
        
        margin-top: 0px;

        &:hover {
            background-color: $gray-300;
        }

        &:disabled {
            background-color: $gray-200;
            

            .material-icons {
                color: $gray-400;
            }
        }

        .material-icons {
            font-size: 20px;
            color: $gray-700;
        }
    }
}

@media screen and (max-width: 767px) {
    .dg-dashboard {
        .dg-dashboard-container {
            width: 100%;
            padding-top: 100px !important;
            margin-left: 0px;
            padding: 40px;
            background-color: #f0f2f4;
            min-height: 100vh;
        }

        tr {
            display: none;
        }
    }
    
}