@import "../../../../styles/variables.scss";

.dg-flags {
    margin-bottom: 30px;

    .carousel-control-prev, .carousel-control-next {
        width: 50px;
        background-color: rgba(0,0,0,0.1);
    }

    .dg-action-item {
        background-color: white;
        width: 100%;
        min-height: 162px;
        border-radius: 5px;
        padding: 20px 70px;
        box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.1);
        border: 1px solid $gray-200;
        cursor:pointer;
    
        button {
            border: none;
            font-weight: bold;
            padding: 3px 10px;
            border-radius: 5px;
            margin-top: 12px;
        }

        &.loading {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            img {
                height: 40px;
            }
        }
    
        &.error {
            background-color: $danger;
    
            button {
                background-color: white;
                border: 1px solid $gray-200;
                color: $danger;
                box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.1);
            }
        }
    
        &.warning {
            background-color: darken($warning, 5);

            button {
                background-color: rgba(255,255,255,0.3);
                text-shadow: 0px 1px 3px rgba(0,0,0,0.1);
                color: white;
                box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.1);
            }
        }

        &.announcement {
            background-color: $brand;

            button {
                background-color: rgba(255,255,255,0.3);
                text-shadow: 0px 1px 3px rgba(0,0,0,0.1);
                color: white;
            }
        }
    
        h4 {
            text-shadow: 0px 1px 3px rgba(0,0,0,0.1);
            color: white;
            font-size: 16px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
    
            .material-icons {
                margin-right: 5px;
                color: white;
            }
        }
    
        p {
            color: white;
            margin-bottom: 15px;
            max-width: 600px;
            opacity: 0.95;
            font-size: 13px;
    
            a {
                font-weight: bold;
    
                span {
                    position: relative;
                    top: 3.5px;
                    font-size: 1.2em;
                }
            }
    
        }
    
        .dg-card-title {
            border-bottom: 1px solid $gray-300;
            padding-bottom: 10px;
            font-weight: bold;
            margin-bottom: 10px;
        }
    }
}