.dg-givingpage-preview {
    border-radius: 5px;
    padding: 30px 20px;
    box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;

    .title {
        text-align: center;
        .preamble {
            color: white;
            opacity: 0.8;
            font-size: 15px;
            display: block;
            margin-bottom: -5px;
        }

        .name {
            font-weight: bold;
            color: white;
            font-size: 20px;
            display: block;
        }
    }

    .buttons {
        background-color: white;
        padding: 10px;
        border-radius: 3px;
        width: 50%;
        box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.1);
        text-align: center;
        margin-top: 30px;
        padding: 15px;

        .prompt {
            color: #FF8F1C;
            font-size: 14px;
            font-weight: bold;
        }

        .buttons-row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin: 10px 0;

            .gp-button {
                background-color: #efefef;
                border-radius: 5px;
                width: 30%;
                height: 40px;
                content: ' ';
            }
        }
    }

    .givesafe {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        margin-top: 20px;
        img {
            height: 10px;
            margin-right: 5px;
        }
    
        .org {
            background-color: #39b54a;
            padding: 5px 3px;
            color: white;
            font-weight: 700;
            display: flex;
            align-items: center;
            border-radius: 5px;
            img {
                height: 7px;
                margin-right: 3px;
            }
            p {
                margin-bottom: 0px;
                font-size: 10px;
                display: inline;
                color: white;
                line-height: 10px;
            }
        }
    }
}