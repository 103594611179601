@import "../../../../styles/variables.scss";


.dg-mobile-table-item {
    list-style-type: none;
    padding: 15px 20px;
    border-bottom: 1px solid $gray-300;
    width: 100%;

    &:hover {
        background-color: $gray-100;
    }

    .headline {
        display: grid;
        grid-template-columns: 90% 10%;
        p {
            font-size: 16px;
            margin-bottom: 0px;
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;


            &.material-icons {
                font-size: 20px;
                cursor: pointer;
                text-align: right;
            }
        }
    }

    .data {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;

        &.false {
            display: none;
        }

        .item {
            width: 50%;
            overflow: hidden;
            text-overflow: ellipsis;

            label {
                font-size: 12px;
                text-transform: uppercase;
                color: $gray-700;
                margin-bottom: 0;
            }

            p {
                font-size: 14px;
                margin-bottom: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}


@media screen and (min-width: 767px) {
    .dg-mobile-table-item {
        display: none;
    }
    
}