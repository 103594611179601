@import "../../../styles/variables.scss";

.dg-footer {
    background-color: $gray-200;
    padding: 20px 3%;
    position: relative;
    border-top: 1px solid $gray-300;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    .slogan {
        height: 10px;
        opacity: 0.5;
    }

    .du {
        height: 30px;
        filter: grayscale(100%);
        opacity: 0.7;
        float: right;

        &:hover {
            opacity: 1;
            filter: none;
        }
    }


}

@media screen and (max-width: 780px) {
    .dg-footer {
        padding: 20px 30px;
    }
}