@import "../../../styles/variables.scss";

.dg-start-button, input[type=submit] {
    font-weight: bold;
    color: white;
    cursor: pointer;
    padding: 15px 20px;
    background-color: $brand;
    border: none;
    display: inline-block;
    margin-top: 40px;
    border-radius: 8px;
    font-feature-settings: normal;

    transition-duration: 0.1s;
    -webkit-transition-duration: 0.1s;

    .flex {
        display: flex;
        align-items: center;
    }

    p {
        margin-bottom: 0px;
        line-height: 1;
        color: white;
    }

    .material-icons {
        color: white;
    }

    &:disabled {
        background-color: $gray-300;
        color: $gray-700;

        p {
            color: $gray-500;
        }
    }

    &:hover {
        outline: 2px solid lighten($brand,30);
        background-color: darken($brand,10);
        transition-duration: 0.1s;
        -webkit-transition-duration: 0.1s;
    }

    &.loading {

        .material-icons {
            animation-name: spin;
            animation-duration: 4000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear; 
        }
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}