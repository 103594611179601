@import "../../../styles/variables.scss";

.dg-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 90vh;
    background-color: $gray-100;
    text-align: center;

    img {
        height: 150px;
        margin-bottom: 20px;
    }
}