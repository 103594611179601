@import "../../../styles/variables.scss";
@import "../../styles/Form.scss";

.dg-page {
    font-family: 'Intelo Alt', sans-serif !important;
    font-feature-settings: 'ss02';
    color: $text-dark;
    transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    min-height: calc(100vh);
    display: flex;
    padding-bottom: 100px;
    font-weight: 400;
    background: rgb(250, 250, 250);
    -webkit-font-smoothing: antialiased;

    * {
        box-sizing: border-box;
    }

    &.dg-dashboard {
        padding-bottom: 0;
    }

    p {
        font-size: 14px;
        max-width: 600px;
        color: $gray-800;
        margin-bottom: 2rem;

        .material-icons {
            line-height: 1.1rem;
        }

    }

    

    .intro {
        color: $gray-800;
        font-size: $font-size-base * 1.15;
        max-width: 700px;
        margin-bottom: 40px;
    }

    .emphasis {
        color: $brand;
    }

    .alert {
        border-width: 0px;
        border-left-width: 2px;
        margin-bottom: 25px;
        padding: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:before {
            font-family: 'Material Icons';
            font-size: 24px;
            line-height: 1;
            margin-right: 10px;
        }

        &.alert-danger {
            border-color: #721c24;

            &:before {
                content: 'close';
            }
        }

        &.alert-success {
            border-color: #155724;

            &:before {
                content: 'done';
            }
        }

        &.alert-warning {
            border-color: #856404;

            &:before {
                content: 'priority_high';
            }
        }

        &.alert-info {
            border-color: #0c5460;

            &:before {
                content: 'lightbulb';
            }
        }
    }

    .aside {
        background-color: rgba(0,0,0,0.04);
        padding: 15px;
        margin-left: -15px;

        p {
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .step-number {
        font-size: 0.8em;
        font-weight: normal;
        color: $gray-600;
    }

    .dg-choice-buttons {
        display: flex;
        justify-content: space-between;
    }

    

    .download-badges {
        img {
            height: 40px;
            margin-right: 20px;
        }
    }

    .dg-page-bg {
        position: absolute;
        height: 100vh;
        right: 0px;
        opacity: 0.5;
        bottom: 0px;
    }
}

/* Modals */

.modal-open {
    .dg-modal {
        .modal-dialog {
            max-width: 750px;
            .modal-content {
                font-family: 'Intelo Alt', sans-serif;
                border-radius: 5px;

                .modal-header {
                    .modal-title {
                        font-size: 1.2em;
                        font-weight: 600;
                    }
                }

                .modal-body {
                    padding: 20px;
                    font-size: 14px;
                    background-color: $gray-100;
                }

                .aside {
                    background-color: rgba(0,0,0,0.04);
                    padding: 15px;
            
                    p {
                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }

                form, .dg-form {
                    width: 100%;
            
                    .form-group {
                        display: block;
                        .form-label, .form-check-label, .custom-control-label {
                            font-size: 13px;
                            color: $gray-700;
                            margin-top: 4px;
                            line-height: 1.2;
                        }
            
                        .form-check-label {
                            font-size: 14px;
                        }
            
                        .form-check, .custom-control-label {
                            padding-top: 4px;
                        }
                
                        .form-control {
                            font-weight: 500;
                            font-size: 14px;
                            border-radius: 5px;
                            box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.1);
            
                            &.invalid + .validation {
                                color: rgb(212, 32, 32);
                            }
            
                            + .validation {
                                padding-top: 5px;
                                font-size: 14px;
                            }
                        }
            
                        .form-text {
                            color: $gray-700;
                            margin-top: 7px;
                        }
                    }
            
                    button {
                        background-color: #fff4e8;
                        outline: none;
                        border: 0px;
                        border-bottom: 2px solid $brand;
                        padding: 7px 10px;
                        font-size: 0.9em;
                        font-weight: 600;
                        color: #333;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 780px) {
    .dg-page {
        display: block;
    }
}