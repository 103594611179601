
//@import "~@dugood/dds/src/shared/variables";
//@import "~@dugood/dds/src/shared/dds";
@import "../../styles/variables.scss";

.dg-dashboard {
    background-color: white;
    font-weight: 500;


    h1 {
        font-family: 'Apercu Pro', sans-serif !important;
    }

    p {
        line-height: 1.4;
        margin-bottom: 12px;
        font-weight: 500;
    }

    .dg-dashboard-container {
        width: 100%;
        padding: 40px;
        background-color: white;
        min-height: 100vh;
        float: right;
    }

    .dg-stats {
        padding: 0;
        .dg-stat {
            padding: 30px 20px;

            &:hover {
                background: linear-gradient(197deg, rgba(255,255,255,1) 0%, rgba(249,249,249,1) 100%);
            }

            &:not(:last-child) {
                border-bottom: 1px solid $gray-300;
            }
            h4 {
                color: $gray-600;
                font-size: 0.9em;
                margin-bottom: 0;
                font-weight: 500;
                
            }
    
            p {
                font-size: 2.5em;
                font-weight: bold;
                letter-spacing: 1px;
                margin-bottom: 0;
                line-height: 50px;
            }
        }
    }

    .dg-quick-actions {
        color: $gray-700;
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        

        button, a {
            border-radius: 20px;
            border: none;
            padding: 5px 12px;
            background-color: lighten($brand, 35);
            border: 1px solid lighten($brand, 30);
            margin: 0 10px;
            color: $gray-800;
            font-weight: 500;
            box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.1);
            font-size: 13px;
            text-decoration: none;

            .material-icons {
                color: $brand;
            }

            &:first-child {
                margin-left: 0;
            }

            &:hover {
                background-color: lighten($brand, 30);
                text-decoration: none;
            }

            span {
                display: flex;
                align-items: center;
                font-size: 0.95em;

                span {
                    margin-right: 5px;
                    font-size: 1.2em;
                }
            }
        }
    }

    .dg-users-list {
        margin: 0 -20px;
        padding: 0;

        .list-section-header {
            background-color: $gray-100;
            border: 1px solid $gray-200;
            border-left: none;
            border-right: none;
            padding: 5px 20px;
            font-size: 14px;
        }

        li {
            list-style-type: none;
            padding: 15px 20px;
            border-top: 1px solid $gray-200;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;

            h5 {
                font-size: 14px;
                display: flex;
                align-items: center;
                margin-bottom: 0;

                .badge {
                    margin-left: 10px;
                }
            }

            &:last-child {
                border-bottom: 1px solid $gray-200;
            }
        }

        .dg-table-actions {
            display: flex;
            flex-direction: row;
            button {
                border: none;
                border-radius: 30px;
                width: 25px;
                height: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(0,0,0,0.1);
                margin-top: 0;
                
                &:not(:last-child) {
                    margin-right: 10px;
                }
    
                .material-icons {
                    font-size: 16px;
                    cursor: pointer;
                    color: #333;
                }
            }
        }
    }

    .dg-ranking {
        padding-bottom: 0px;
    }

    form, .dg-form {
        width: 100%;

        .form-group {
            display: block;

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            .form-label, .form-check-label, .custom-control-label {
                font-size: 13px;
                color: $gray-800;
                margin-top: 4px;
                line-height: 1.2;
                font-weight: 400;
            }

            .form-check-label {
                font-size: 14px;
            }

            .form-check, .custom-control-label {
                padding-top: 4px;
            }
    
            .form-control {
                font-weight: 500;
                font-size: 14px;
                border-radius: 5px;
                box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.1);
                margin: 8px 0;

                &.invalid + .validation {
                    color: rgb(212, 32, 32);
                }

                + .validation {
                    padding-top: 5px;
                    font-size: 14px;
                }
            }

            .form-text {
                color: $gray-800;
                margin-top: 12px;
                font-size: 13px;
                font-weight: 400;
                line-height: 1.2;
            }
        }

        button {
            background-color: #fff4e8;
            outline: none;
            border: 0px;
            border-bottom: 2px solid $brand;
            padding: 7px 10px;
            font-size: 0.9em;
            font-weight: 600;
            color: #333;
            margin-top: 10px;
        }
    }

    .alert {
        border-width: 0px;
        border-left-width: 2px;
        margin-bottom: 25px;
        padding: 10px;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:before {
            font-family: 'Material Icons';
            font-size: 24px;
            line-height: 1;
            margin-right: 10px;
        }

        &.alert-danger {
            border-color: #721c24;

            &:before {
                content: 'close';
            }
        }

        &.alert-success {
            border-color: #155724;

            &:before {
                content: 'done';
            }
        }

        &.alert-warning {
            border-color: #856404;

            &:before {
                content: 'priority_high';
            }
        }

        &.alert-info {
            border-color: #0c5460;

            &:before {
                content: 'lightbulb';
            }
        }
    }
    
}

@media screen and (max-width: 900px) {
    .dg-dashboard {
        .dg-quick-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            button, a {
                width: 100%;
                margin-bottom: 10px;
                margin-left: 0px;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .dg-dashboard {
        .dg-dashboard-container {
            padding: 16px !important;
            box-sizing: border-box;
            background-color: white;
            min-height: 100vh;
            width: 100vw;
        }
    }
    
}