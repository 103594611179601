@import "../../../styles/variables.scss";

.dg-invoice + .dg-footer {
    display: none;
}

.dg-invoice {
    height: 100vh;
    max-height: 100vh;
    width: 100%;
    font-family: 'Inter',sans-serif;
    font-size: 16px;
    padding: 50px 0;
    box-sizing: border-box;

    .no-print {
        background-color: white;
        height: 100vh;
        width: 100vw;
        position: fixed;
        border-radius: 0px !important;
        z-index: 20;
        color: $gray-500;
        display: flex;
        align-items: center;
        justify-content: center;

        .material-icons {
            font-size: 150px;
            cursor: pointer;
        }
    }

    .header {
        width: 100%;

        .col-logo {
            img {
                width: 200px;
            }
        }

        .col-address {
            margin-top: 30px;
            line-height: 1.2;
        }
    }

    .intro {
        margin-top: 120px;

        h2 {
            font-weight: bold;
            color: $brand;
            font-size: 30px;
            margin-bottom: 16px;
        }

        h3 {
            color: $gray-700;
            margin-bottom: 12px;

            .orgName {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

    .totals {
        margin-top: 75px;
        display: flex;
        .total {
            margin-right: 60px;
            h2 {
                font-weight: bold;
                font-size: 40px;
                margin-bottom: 12px;
            }

            p {
                color: $gray-700;
            }
        }
    }

    .breakdown {
        margin-top: 75px;
        
        h3 {
            font-size: 24px;
            color: $gray-800;
            margin-bottom: 30px;
        }

        .breakdown-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $gray-400;
            padding: 20px 0;

            h4 {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 12px;
            }

            p {
                margin-bottom: 8px;

                &.percentage {
                    color: $brand;
                }
            }


        }
    }
}


@media print {
    .dg-invoice .no-print {
        display: none;
    }

    .dg-invoice {
        padding: 20mm !important;
    }
}

@page {
    margin: 10mm !important;
}