@import "../../../styles/variables.scss";

.dg-poster + .dg-footer {
    display: none;
}

.dg-poster-viewer {
    width: 100%;
    height: 100vh;
}

.dg-poster {
    font-family: 'Inter',sans-serif;
    -webkit-print-color-adjust: exact;
    margin: 0 !important;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;

    .no-print {
        background-color: white;
        height: 100%;
        width: 100%;
        position: fixed;
        border-radius: 0px !important;
        z-index: 20;
        color: $gray-500;
        display: flex;
        align-items: center;
        justify-content: center;

        .material-icons {
            font-size: 150px;
            cursor: pointer;
        }
    }

    .header {
        width: 100%;
        background-color: $brand;
        padding: 100px 100px 50px 85px;
        color: white;
        text-align: center;
        clip-path: polygon(0% 0%, 100% 0, 100% 85%, 50% 100%, 0 85%);
        padding-bottom: 100px;
        border-radius: 36px 36px 0 0;
        box-sizing: border-box;

        h3 {
            font-size: 50px;
            font-weight: normal;
            margin-bottom: 36px;
        }

        h1 {
            font-size: 85px;
            font-weight: bold;
            line-height: 1;
            margin-bottom: 60px;
        }
    }

    .main {
        width: 100%;
        padding: 50px 0 30px 0;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        box-sizing: border-box;

        #qr {
            width: 600px;
            margin: 30px 0 50px 0;
        }

        h4 {
            padding-left: 55px;
            font-size: 20px;
            font-weight: bold;
        }

        h5 {
            padding-left: 55px;
            font-size: 20px;
            font-weight: bold;
            color: #333;
        }

        .instructions {
            p {
                font-weight: bold;
            }
        }

        p {
            font-size: 28px;
            margin-bottom: 16px;
        }

        .logos {
            margin-top: 20px;
            margin: auto;
            width: 400px;
            display: flex;
            justify-content: space-between;
            div {
                width: auto;
                img {
                    height: 50px;
                }
            }
        }
    }

    .footer {  
        width: 100%;
        padding: 0 50px;
        height: 100px;
        position: absolute;
        bottom: 0px;
        background-color: $gray-100;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        border-radius: 36px;

        p {
            font-size: 16px;
            margin-bottom: 0;
            font-weight: 500;
        }

        .logo {
            display: flex;
            align-items: center;
            img {
                height: 30px;
            }

            .givesafe {
                margin-left: 10px;
                background-color: #39b54a;
                color: white;
                font-weight: bold;
                display: flex;
                align-items: center;
                border-radius: 3px;
                padding: 3px 5px;
                margin-top: -5px;

                img {
                    height: 13px;
                    margin-right: 5px;
                }
            }
        }

        .copyright-blurb {
            font-size: 11px;
            float: right;
            width: 310px;

            p {
                font-size: 11px;
                text-align: right;
            }
        }
    }
}

@media print {
    .dg-poster .no-print {
        display: none;
    }
    
}