@import "../../styles/variables.scss";

.dg-page {
    form, .dg-form {
        width: 100%;
        font-family: 'Intelo Alt', sans-serif;
        .form-group {
            .form-label, .form-check-label, .custom-control-label {
                font-size: 13px;
                color: $gray-700;
                margin-top: 4px;
                line-height: 1.2;
            }

            .form-check-label {
                font-size: 14px;
            }

            .form-check, .custom-control-label {
                padding-top: 4px;
            }
    
            .form-control {
                font-weight: 500;
                font-size: 14px;
                border-radius: 8px;
                box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.1);
                font-family: 'Intelo Alt', sans-serif;
                margin: 8px 0;
                padding: 12px 6px;
                line-height: 14px;
                box-sizing: border-box;

                &:focus {
                    border: 1px solid $brand;
                    outline: 2px solid lighten($brand,35);
                }

                &.invalid {
                    border: 1px solid rgb(212, 32, 32);
                    outline: 2px solid lighten(rgb(212,32,32),35);
                }

                &.invalid + .validation {
                    color: rgb(212, 32, 32);
                }

                + .validation {
                    padding-top: 5px;
                    display: block;
                    font-size: 13px;
                }

                &.form-check-input {
                    height: 15px;
                    width: 15px;
                    left: 0px;
                }
            }

            .form-text {
                color: $gray-700;
                margin-top: 7px;
            }

            .input-group {
                .form-control {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
                .input-group-text {
                    border-radius: 3px 0 0 3px;
                    font-weight: 500;
                }
            }

            .dg-colorpicker {
                border-radius: 5px;
                border: 1px solid rgb(206, 212, 218);
                box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.1);
                input[type='color'] {
                    width: 10% !important;
                    flex-shrink: 2;
                    padding: 0px;
                    border-radius: 5px 0 0 5px;
                    opacity: 0;
                    cursor: pointer;
                    
                }

                input:nth-child(2) {
                    flex-grow: 2;
                    width: 90%;
                    border: none;
                }
            }
        }

        button, input[type='submit'] {
            font-weight: bold;
            color: white;
            cursor: pointer;
            padding: 15px 20px;
            background-color: $brand;
            border: none;
            display: inline-block;
            margin-top: 40px;
            border-radius: 8px;
            line-height: 1;
            font-family: 'Intelo Alt', sans-serif;

            transition-duration: 0.1s;
            -webkit-transition-duration: 0.1s;

            .flex {
                display: flex;
                align-items: center;
            }

            p {
                margin-bottom: 0px;
                line-height: 1;
                color: white;
            }

            .material-icons {
                color: white;
            }

            &:disabled {
                background-color: $gray-300;
                color: $gray-700;

                p {
                    color: $gray-500;
                }
            }

            &:hover {
                outline: 2px solid lighten($brand,30);
                background-color: darken($brand,10);
                transition-duration: 0.1s;
                -webkit-transition-duration: 0.1s;
            }
        }
    }

    
}