@import "../styles/variables.scss";

.dg-tabs {

    margin-top: 48px;
    max-width: 450px;

    button {
        margin-top: 24px;
    }

    h3 {
        margin-bottom: 8px;
    }
    
}

p {
    &:not(:last-child) {
        margin-bottom: 12px;
    }
}


.dg-onboarding {
    background-color: #FEF7FF;
    padding: 40px;

    

    .dg-container {
        margin-top: 50px;
        max-width: 1200px;
        margin: 50px auto;
    }

    .logo-container {
        height: 55px;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
    }

    h1 {
        font-size: 2.25em;
        line-height: 1.1;
        margin-bottom: 24px;
    }

    .logo {
        height: 25px;
    }

    .left {

        .dg-left-content {
            margin-top: 100px;

            

            h1, p {
                color: white;
            }
        }

        

        
    }

    

    .success-icon {
        width: 75px;
        margin-bottom: 30px;
    }
    
    .tab-content {
        max-width: 600px;
    }
    
    .org-types {
    
        button {
            background-color: white;
            outline: none;
            border: 1px solid #dadada;
            border-radius: 2px;
            width: 100%;
            display: flex;
            flex-direction: row;
            text-align: left;
            padding: 20px 15px;
            margin-bottom: 25px;
    
            &.active {
                border-color: $brand;
            }
    
            .img {
                width: 15%;
                
                margin-right: 5%;
                text-align: left;
    
                img {
                    max-width: 55px;
                    max-height: 55px;
                }
            }
    
            .text {
                width: 80%;
                p {
                    font-size: 14px;
                    margin-bottom: 0;
                    color: $gray-800;
                }
            }
        }
    }
}

.dg-mt {
    margin-top: 24px;
}