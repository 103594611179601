.dg-container {
    width: 100%;
    max-width: 900px;
    padding: 0 20px;
    margin-top: 150px;
    padding-left: 5%;
    padding-right: 5%;
}

.dg-page-title + .dg-container {
    margin-left: 35%;
}

@media screen and (max-width: 780px) {
    .dg-container {
        margin-top: 50px;
        padding-right: 30px;
        padding-left: 30px;
    }

    .dg-page-title + .dg-container {
        margin-left: 0%;
    }
}