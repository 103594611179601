@import "../../../styles/variables.scss";

.dg-page-title {
    display: inline-block;
    background-color: #333f50;
    border-top: 5px solid $brand;
    border-right: 1px solid $gray-200;
    background-size: 140%;
    color: white;
    padding: 5% 3% !important;
    box-sizing: border-box;
    height: 100%;
    min-height: 100vh;
    width: 35%;
    position: fixed;

        .logo {
            height: 25px;
            margin-bottom: 50px;
        }
        
        .text {
            

            h3 {
                color: white;
                font-weight: normal;
                font-size: 1.3em;
                opacity: 0.8;
            }
    
            h1 {
                font-weight: 700;
                color: white;
                font-size: 2.5em;
                line-height: 1.1;
            }
            
        }

        .dg-progress-bar {
            height: 10px;
            border-radius: 20px 0 0 20px;
            background-color: white;
            position: relative;

            &:before {
                width: 100px;
                background-color: rgba(255,255,255,0.25);
                content: '';
                height: 10px;
                position: absolute;
                left:1px
            }
        }
}

@media screen and (max-width: 780px) {
    .dg-page-title {
        width: 100%;
        display: block;
        min-height: unset;
        padding: 30px !important;
        position: static;
    }
}