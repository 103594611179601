li {
    list-style-type: none;

    .dg-list_item--campaign {

        margin-bottom: 16px;

        .dg-card_inner {

            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;

            .dg-list_item--campaign_img {
                width: 50px;
                height: 50px;
                background-color: blue;
                border-radius: 6px;
                overflow: hidden;
                flex-shrink: 0;

                img {
                    width: 50px;
                    height: 50px;
                }

                
            }

            .dg-list_item--campaign_details {
                width: 100%;
                margin: 0 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                
                h4 {
                    margin-bottom: 4px;
                }

                p {
                    margin-bottom: 0;
                }
            }

            .dg-list_item--campaign_actions {
                flex-shrink: 0;
                display: flex;

                div {
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                    justify-content: center;

                    p {
                        font-size: 13px;
                    }
                    .dg-iconButton {
                        margin-right: 0 !important;
                    }

                    &:not(:last-child) {
                        margin-right: 16px;
                    }
                }
            }

        }
    }
}

.dg-qr-modal {
    .dg-qr-modal_img {
        width: 100%;
        border-radius: 6px;
    }

    .dg-qr-modal_details {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        .dg-qr-modal_tally {
            em {
                font-size: 2.5em;
                font-weight: 700;
            }
        }

        .dg-qr-modal_cards {

            .dg-card {
                width: 100%;
                cursor: pointer;
                
                .dg-card_inner {
                    display: flex;
                    align-items: center;
                }
                
                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                
                .dg-iconButton {
                    margin-right: 16px;
                }
            }
        }
    }

    
}
