@import "../../../../styles/variables.scss";
.dg-card-old {
    background-color: white;
    width: 100%;
    border: 1px solid $gray-200;
    border-radius: 5px;
    font-size: 16px !important;
    padding: 20px;
    box-shadow: 0px 2px 2px -1px rgba(0,0,0,0.1);
    margin-bottom: 30px;
    overflow: hidden;
    .dg-card-old-title {
        color: $gray-800;
        padding-bottom: 10px;
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 0.9rem;
        .dg-action {
            margin-left: 10px;
            font-weight: normal;
            cursor: pointer;
            padding: 0;
            background-color: white;
            border: none;
            margin-top: 0px;
            color: $brand;
        }
    }
    &.sticky {
        position: sticky;
        top: 40px;
    }
}