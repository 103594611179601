.dg-step-visual {
    .illus-holder {
        height: 140px;
        width: 100%;
        background-color: #343f5024;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: inset 0px -2px 12px 0px rgba(0,0,0,0.2);
        margin-bottom: 20px;
        img {
            width: 120px;
    
        }
    }
    
}