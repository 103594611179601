.dg-old-loader {
    position: fixed;
    z-index: 20;
    width: 100vw;
    height: 100vh;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .dg-logo {
        width: 200px;
        margin-bottom: 20px;
    }

    .dg-loading {
        width: 40px;
    }
}


@media screen and (max-width: 780px) {
    .dg-loader {
        margin-top: -75px;
    }
}